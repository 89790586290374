<template>
  <div class="mt-2 container-fluid">
    <div class="row">
      <div class="col-lg-12 mx-auto">
        <div class="card">
          <div class="card-header">
            <i class="fa fa-users"></i> Personas            
          </div>
          <div class="card-body">  
            <div class="row mb-2">
              <div class="col-lg-4">

                <div class="form-group">                       
                  <div class="input-group">
                    <input v-model="valor" type="text" class="form-control form-control-sm" placeholder="Buscar por Nombre o Apellido ..." v-on:keyup.enter="listar()">
                    <a class="btn btn-secondary btn-sm" @click="listar"><i class="fa fa-search"></i></a>
                    <a class="btn btn-secondary btn-sm" @click="valor='';listar()"><i class="fa fa-times"></i></a>
                  </div>
                </div>

              </div>
              <div class="col-lg-4">
                <a class="btn btn-success btn-sm" @click="showModal()"><i class="fa fa-plus"></i> Nuevo</a>
              </div>
            </div>
            <div class="row">             
              <div class="col md-12">
                <div class="card border-primary">                    
                  <div class="card-body">  
                    <div class="table-responsive" style="overflow-y:scroll; height:550px;">
                      <table class="table table-sm table-bordered table-condensed">
                        <thead>
                          <tr class="table-secondary">
                            <th class="text-center">Nro.</th>
                            <th>Nombre</th>
                            <th>Carnet</th>
                            <th>Correo</th>
                            <th>Teléfono / Celular</th>
                            <th>Estado</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>                          
                        <tbody>
                          <tr v-for="(item, index) in registros" :key="index">
                            <td class="text-center">{{ ((currentPage*perPage) - perPage) + (index+1)}}</td>
                            <td>
                              {{item.paterno}} {{item.materno}} {{item.nombres}}                   
                            </td>
                            <td>
                              {{item.dip}}                   
                            </td>
                            <td>
                              {{item.correo}}                   
                            </td>
                            <td>
                              {{item.telefono}} <br>
                              {{ item.celular }}               
                            </td>                           
                            <td>
                              <span class="badge bg-success" v-if="item.estado==1">Activo</span>
                              <span class="badge bg-danger" v-else>Inactivo</span>
                            </td>
                            <td>
                              <a class="btn btn-sm btn-warning mr-4" @click="showModal(item)"><i class="fa fa-edit"></i></a>
                              <a class="btn btn-sm btn-danger ml-4" @click="baja(item)"><i class="fa fa-times"></i></a>
                            </td>                      
                          </tr>
                        </tbody>
                      </table>                        
                    </div>

                    <div class="overflow-auto">
                      <b-pagination
                        class="mb-1 mt-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-text="<<"
                        prev-text="<"
                        next-text=">"
                        last-text=">>"
                        size="sm"
                        @change="cambio"
                        v-if="rows>10"
                      ></b-pagination>
                      <small class="text-muted">Página: {{ currentPage}} | Registros: del {{ ((currentPage*perPage) - perPage)+1 }} al {{currentPage*perPage}} | Total:  {{rows}}</small>                       
                    </div>

                  </div>
                </div>

              </div>
            </div>

          </div>
        </div> 
      </div>
    </div>
    <Loading v-show="cargando" />

    <b-modal
        class="modal fade"
        :title="tituloModal"
        size="lg"
        hide-footer
        ref="modalAm"
        :no-close-on-backdrop="true"
    >
      <div class="container">
        <form @submit.prevent="procesar">
          <div class="row">
            <div class="col-lg-6">
              <div class="row">
                <label>Nro. Documento:</label>
                <span
                  class="lb-error"
                  v-if="!$v.registro.dip.required"
                  >Campo requerido
                </span>
                <input
                  class="form-control"
                  :class="{ 'is-invalid': $v.registro.dip.$error }"
                  v-model.trim="$v.registro.dip.$model"
                  placeholder="Nro. Documento..." 
                  required="true"     
                  @keyup="registro.dip = registro.dip.toUpperCase()"      
                >
              </div>
              <div class="row">
                <label>Nombres:</label>
                <span
                  class="lb-error"
                  v-if="!$v.registro.nombres.required"
                  >Campo requerido
                </span>
                <input
                  class="form-control"
                  :class="{ 'is-invalid': $v.registro.nombres.$error }"
                  v-model.trim="$v.registro.nombres.$model"
                  placeholder="Nombres..." 
                  required="true"     
                  @keyup="registro.nombres = registro.nombres.toUpperCase()"      
                >
              </div>
              <div class="row">
                <label>Paterno:</label>
                <span
                  class="lb-error"
                  v-if="!$v.registro.paterno.required"
                  >Campo requerido
                </span>
                <input
                  class="form-control"
                  :class="{ 'is-invalid': $v.registro.paterno.$error }"
                  v-model.trim="$v.registro.paterno.$model"
                  placeholder="Paterno..." 
                  required="true"     
                  @keyup="registro.paterno = registro.paterno.toUpperCase()"      
                >
              </div>
              <div class="row">
                <label>Materno:</label>
                <span
                  class="lb-error"
                  v-if="!$v.registro.materno.required"
                  >Campo requerido
                </span>
                <input
                  class="form-control"
                  :class="{ 'is-invalid': $v.registro.materno.$error }"
                  v-model.trim="$v.registro.materno.$model"
                  placeholder="Materno..." 
                  required="true"     
                  @keyup="registro.materno = registro.materno.toUpperCase()"      
                >
              </div>
            </div>
            <div class="col-lg-6">
              <div class="row">
                <label>Dirección:</label>
                <span
                  class="lb-error"
                  v-if="!$v.registro.direccion.required"
                  >Campo requerido
                </span>
                <input
                  class="form-control"
                  :class="{ 'is-invalid': $v.registro.direccion.$error }"
                  v-model.trim="$v.registro.direccion.$model"
                  placeholder="Dirección..." 
                  required="true"     
                  @keyup="registro.direccion = registro.direccion.toUpperCase()"      
                >
              </div>
              <div class="row">
                <label>Correo Electrónico:</label>
                <span
                  class="lb-error"
                  v-if="!$v.registro.correo.required"
                  >Campo requerido
                </span>
                <span
                  class="lb-error"
                  v-if="!$v.registro.correo.email"
                  >Correo no válido
                </span>
                <input
                  class="form-control"
                  :class="{ 'is-invalid': $v.registro.correo.$error }"
                  v-model.trim="$v.registro.correo.$model"
                  placeholder="Correo Electrónico..." 
                  required="true"          
                >
              </div>
              <div class="row">
                <label>Teléfono:</label>
                <span
                  class="lb-error"
                  v-if="!$v.registro.telefono.required"
                  >Campo requerido
                </span>
                <input
                  class="form-control"
                  :class="{ 'is-invalid': $v.registro.telefono.$error }"
                  v-model.trim="$v.registro.telefono.$model"
                  placeholder="Teléfono..." 
                  required="true"     
                  @keyup="registro.telefono = registro.telefono.toUpperCase()"      
                >
              </div>
              <div class="row">
                <label>Celular:</label>
                <span
                  class="lb-error"
                  v-if="!$v.registro.celular.required"
                  >Campo requerido
                </span>
                <input
                  class="form-control"
                  :class="{ 'is-invalid': $v.registro.celular.$error }"
                  v-model.trim="$v.registro.celular.$model"
                  placeholder="Celular..." 
                  required="true"     
                  @keyup="registro.celular = registro.celular.toUpperCase()"      
                >
              </div>
            </div>
          </div>
          
        
          <hr>
          <div class="row">
            <div class="col-lg-12 text-end">
              <a class="btn btn-secondary float-left" @click="hideModal" >Cancelar</a>
              <button type="submit" v-if="!edicion" class="btn btn-primary float-end" :disabled="$v.$invalid"><i class="fa fa-save"></i> Registrar</button>
              <button type="submit" v-if="edicion" class="btn btn-warning float-end" :disabled="$v.$invalid"><i class="fa fa-edit"></i> Editar</button>
            </div>
          </div>
        </form>
      </div>
    </b-modal>

  </div>
</template>

<script>
import { required, minLength , email, helpers, maxLength} from "vuelidate/lib/validators";
import Loading from '@/components/Loading';
import moment from 'moment';

const letras = helpers.regex("alpha", /^[a-zA-ZñÑ ]*$/);
const numero = helpers.regex("numb",/^[0-9]\d*$/);

export default {
  data() {
    return {
      cargando:false,
      registros: [],
      currentPage:1,
      rows:0,
      perPage:10,
      valor:'',
      registro:{
        nombres: '',        
        paterno: '',        
        materno: '',        
        dip: '',
        direccion: '',        
        correo: '',        
        telefono: '',        
        celular: '',        
      },
      edicion: false,
      tituloModal: 'Nuevo Registro',
    }
  },
  components: {
    Loading    
  },
  
  methods: {
    async listar() {
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/personas/${this.currentPage}/${this.valor}`);
        this.registros = resultados.data.contenido.registros; 
        this.rows = resultados.data.contenido.total;
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },
    cambio(value){
      this.currentPage = value;
      this.listar();
    },
    formatoFecha(fecha){
      return moment(fecha).format('DD/MM/YYYY');
    },
    formatoHora(fecha){
      return moment(fecha).format('HH:mm:ss');
    },
    procesar(){
      this.$v.$touch();
      if(this.$v.invalid){
        return;
      }
      else{
        if(this.edicion){this.editar();}else{this.guardar();}
      } 
    },
    async guardar(){
      this.cargando = true;
      try {
        await this.axios.post(`/persona`, this.registro);        
        this.hideModal();
        this.cargando = false;        
        this.listar();
        this.$alert("Se agregó correctamente","Atención","success"); 
      } catch (error) {
        console.log(error);
        this.cargando = false;
      }
    },
    async editar(){
      this.cargando = true;
      try {
        await this.axios.put(`/persona/${this.registro.idpersona}`, this.registro);        
        this.hideModal();
        this.cargando = false;        
        this.listar();
        this.$alert("Se editó correctamente","Atención","success"); 
      } catch (error) {
        console.log(error);
        this.cargando = false;
      }
    },
    baja(item) {
      this.$confirm("Se dará de baja el registro, ¿Está seguro?", "Atención", "question", { confirmButtonText: 'SI',cancelButtonText: 'Cancelar' }).then(async (result) => {        
        if (result) {
          try {
            await this.axios.patch(`/persona/${item.idpersona}`);
            this.listar();
            this.$alert("Se dio de baja correctamente","Atención","success");  
          } catch (error) {
            console.log(error);
          }
        }
      }).catch(()=>{});
    },
    showModal(item) {
      if(item!=undefined){
        this.registro = item;
        this.edicion= true;
        this.$refs["modalAm"].show();
        this.tituloModal='Editar Registro';
      }else{
        this.registro = {
          nombres: '',        
          paterno: '',        
          materno: '',        
          dip: '',
          direccion: '',        
          correo: '',        
          telefono: '',        
          celular: ''
        };
        this.edicion= false;
        this.$refs["modalAm"].show();
        this.tituloModal='Nuevo Registro';
      }      
    },
    hideModal() {
      this.$refs["modalAm"].hide(); 
    }  
  },
  created(){
    this.listar();
  },
  validations: {
    registro: {      
      nombres: { required, letras, minLength: minLength(3) },
      paterno: { required, letras, minLength: minLength(3) },
      materno: { required, letras, minLength: minLength(3) },
      dip: { required, numero, minLength: minLength(7) },    
      direccion: { required, minLength: minLength(5) },
      correo: { required, email },        
      telefono: { required, numero },  
      celular: { required, numero}, 
    }
  }

}
</script>
